import React from 'react';

import { BroadbandBannerContent, SPLITIO_KEY, useJSONConfig, useSplitIO } from '@sky-tv-group/shared';

import { configService } from '../../services';

interface BroadbandBannerProps {
  priceOverride?: string;
}

function BroadbandBanner({ priceOverride }: BroadbandBannerProps) {
  const config = useJSONConfig(configService);
  const [BBCreditOfferFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_CREDIT_OFFER);

  const content: BroadbandBannerContent = config?.broadband?.acquisition?.mainBanner;

  if (!content) return null;
  if (priceOverride) content.price = priceOverride;

  return (
    <div>
      {/* mobile */}
     <div className='flex flex-col banner-bg  lg:hidden'>
      <div>
        <img src='https://static.sky.co.nz/sky/broadband/img/Skybroadband_Mobile_BB_SPA_FebOffer_banner.jpg' style={{
          position:'relative',
          top:'-0.625rem'
        }} />
      </div>
      <div className="flex-wrap z-10  sky-h2-black sky-text-white lg:hidden block relative text-center">
              {BBCreditOfferFlag ? (
                <p>
                  <span className='sky-h1-black md:sky-h2-black'style={{  color: '#F2A040' }}>JOIN & SAVE </span>
                  <p className='sky-h6-reg md:sky-h5-reg sky-text-white' >
                  Sign up to a Sky Broadband Fibre Plan and save up to <span style={{ color: '#F2A040' }}>$15/month</span> for the first 12 months.
                  </p>
                </p>
              ) : (
                content.title
              )}
            {BBCreditOfferFlag ? (
              <p className="sky-h7-reg md:sky-label sky-text-white text-center my-2">
                Offer end 30 April 2025.{' '}
                <a
                  className="md:inline underline sky-h7-reg md:sky-label "
                  href="https://get.sky.co.nz/offer-terms"
                  >
                  Offer T&Cs
                </a>
                <span className="banner_font_reg"> and </span>
                <a
                  className="md:inline underline sky-h7-reg md:sky-label "
                  href="https://help.sky.co.nz/s/article/early-termination-fee"
                  >
                  exit fees
                </a>
                <span className="banner_font_reg"> apply.</span>
              </p>
            ) : (
              <>
                <p className="sky-h7-reg md:sky-h6-reg sky-text-white mt-5" style={{ lineHeight: '30px' }}>
                  {content.description}
                </p>
                <div className="mt-2">
                  <span className="sky-h2-bold text-bborange">{content.price}</span>
                  <span className="sky-h2-bold sky-text-white">{content.other}</span>
                </div>
              </>
            )}
          </div>
     </div>

     <div        className="hidden lg:flex relative w-100 h-120 d-flex align-items-center  bg-cover pt-72 pb-12 md:pt-32 md:pb-10 text-center lg:text-left px-6"
        style={{
          backgroundImage:
            'url(' + 'https://static.sky.co.nz/sky/broadband/img/Skybroadband_Desktop_BB_SPA_FebOffer_banner.jpg' + ')',
          height: '340px',
          backgroundPosition:'50% 30%'
        }}>
        <div className="flex flex-col items-center justify-center pl-5 ">
          <div className="z-10 relative">
            <div
              className="sky-text-white hidden lg:block my-1"
              style={{  width: '513px'  }}>
              {BBCreditOfferFlag ? (
                <p>
                  <span className='md:sky-h2-black my-1' style={{ color: '#F2A040',fontSize: '56px',lineHeight: '46px'  }}>JOIN & SAVE </span>
                  <p className='md:sky-h2-bold' style={{ color: '#FFF', fontSize: '20px', lineHeight: '24px',marginTop: '12px' }}>Sign up to a Sky Broadband Fibre Plan and save up to <span style={{ color: '#F2A040' }}>$15/month</span> for the first 12 months.</p>
                  </p>
              ) : (
                content.title
              )}
            </div>

            {BBCreditOfferFlag ? (
              <div  style={{width:'513px'}}>
                <p className="sky-h3-reg sky-text-white" style={{ lineHeight: '120%', fontSize: '16px',marginTop: '12px' }}>
                Offer end 30 April 2025.{' '}
                  <a
                    className="hidden md:inline underline sky-h3-reg sky-text-white"
                    style={{ fontSize: 16 }}
                    href="https://www.sky.co.nz/offer-terms">
                    Offer T&Cs
                  </a>
                  <span className="banner_font_reg"> and </span>
                  <a
                    className="hidden md:inline underline sky-h3-reg sky-text-white"
                    style={{ fontSize: 16 }}
                    href="https://help.sky.co.nz/s/article/early-termination-fee">
                    exit fees
                  </a>
                  <span className="banner_font_reg"> apply.</span>

                </p>
              </div>
            ) : (
              <>
                <p className="sky-h6-reg sky-text-white mt-5" style={{ lineHeight: '30px' }}>
                  {content.description}
                </p>
                <div className="mt-2">
                  <span className="sky-h2-bold text-bborange">{content.price}</span>
                  <span className="sky-h2-bold sky-text-white">{content.other}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="absolute w-full h-1/2 bottom-0"
        style={{ background: `linear-gradient(to top, #00013A 25%, rgba(2, 0, 36, 0) 85% )` }}></div>
    </div>
  );
}

export { BroadbandBanner };
