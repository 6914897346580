/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import {
  productSkuIds,
  T_Product,
  useSplitIO,
  SPLITIO_KEY,
  useBBname,
  redirectToSubdomain,
  formatMoney,
  IPopUpContentConfig,
  useJSONConfig,
  useCartContainer,
  productStoreApi,
} from '@sky-tv-group/shared';
import { ProductCardFragment } from '@sky-tv-group/graphql';

import { useStore } from '../../store';
import { configService, kkService } from '../../services';
import { BlockLoader, GenericProductCard } from '@sky-tv-group/components';
import { isBrowser, isTablet, isMobile } from 'react-device-detect';
import { focusOnAddressSelector } from '../../helper';
import { wireUpProductInfo } from '../../helper/wireUpProductInfo';
import { useAuth0 } from '../../auth0/store';

interface PlanSelectorProps {
  isActiveSkyStarter: boolean | undefined;
  onSelectChange: (product: T_Product, isBundle: boolean) => void;
  isUserAllowedToContinue?: boolean;
  canPurchaseBroadband?: boolean;
  broadbandProducts: MoreChoicesForYouProduct[];
}

export interface MoreChoicesForYouProduct {
  product: ProductCardFragment;
  kkProduct?: T_Product;
}

const CardPlanSelector = ({
  onSelectChange,
  isActiveSkyStarter,
  isUserAllowedToContinue = true,
  canPurchaseBroadband,
  broadbandProducts,
}: PlanSelectorProps) => {
  const bbName = useBBname();
  const config = useJSONConfig(configService);
  const { isAuthenticated } = useAuth0();
  const kkProducts = productStoreApi.getState().products;
  const [showOneMonthOnUs] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_ONE_MONTH_ON_US_ON);
  const { starter, starterAdded } = useCartContainer(kkService, 'ACQUISITION');
  const { setSelectedCardSku, isUpdatingPlan } = useStore();
  const [allowRedirectToIce] = useSplitIO(SPLITIO_KEY.REDIRECT_BB_BUNDLES_TO_ICE);
  const { selectedAddressId, selectedTuiAddress } = useStore(s => ({
    selectedAddressId: s.selectedAddressId,
    selectedTuiAddress: s.selectedTuiAddress,
  }));
  const [skySportPriceChangePopUpEnable] = useSplitIO(SPLITIO_KEY.SKYWEB_SKY_PRICE_CHANGE_POPUP);
  const skySportsPriceChangeResponse: IPopUpContentConfig[] = config?.popUps?.priceChanges?.content;
  const skySportsPriceChangeData = skySportPriceChangePopUpEnable ? skySportsPriceChangeResponse : undefined;

  const plans = starter ? wireUpProductInfo([...kkProducts, starter], bbName) : wireUpProductInfo(kkProducts, bbName);
  const rollingTermsContent = (isStarter: boolean) => {
    let copy = {
      "data": {},
      "content": [
          {
              "data": {},
              "content": [
                  {
                      "data": {},
                      "marks": [],
                      "value": "On a rolling monthly plan. Offer ends 30 April 2025. ",
                      "nodeType": "text"
                  },
                  {
                      "data": {
                          "uri": "https://www.sky.co.nz/offer-terms"
                      },
                      "content": [
                          {
                              "data": {},
                              "marks": [],
                              "value": "Offer T&Cs",
                              "nodeType": "text"
                          }
                      ],
                      "nodeType": "hyperlink"
                  },
                  {
                      "data": {},
                      "marks": [],
                      "value": " ",
                      "nodeType": "text"
                  },
                  {
                      "data": {},
                      "marks": [],
                      "value": " apply.",
                      "nodeType": "text"
                  }
              ],
              "nodeType": "paragraph"
          }
      ],
      "nodeType": "document"
  }
    return copy;
  };

  const getExploreProductByPlanSku = (sku: string) => {
    if (sku === productSkuIds.broadbandStarterDiscountHundredMeg.primary) {
      return plans?.find(p => p.sku === productSkuIds.broadbandStarterDiscountHundredMeg.primary);
    } else if (sku === productSkuIds.broadbandStarterDiscountOneGig.primary) {
      return plans?.find(p => p.sku === productSkuIds.broadbandStarterDiscountOneGig.primary);
    } else if (sku === 'bb_starter_bundle') {
      return plans?.find(p => p.sku === productSkuIds.broadbandEssnFibre.primary);
    }
    return plans?.find(p => p.sku === sku);
  };

  // Select broadband product based on if user has booster/mesh in their cart
  const handleCardSelect = (sku: string, isBundle: boolean) => {
    const selectedBBProduct = broadbandProducts.find(p => p.kkProduct?.sku === sku);

    let bundleName: string = '';

    if (isBundle) {
      switch (sku) {
        case productSkuIds.broadbandStarterDiscountOneGig.primary:
          bundleName = 'bb_pro_bundle';
          break;
        case productSkuIds.broadbandStarterDiscountHundredMeg.primary:
          bundleName = 'bb_everyday_bundle';
          break;
        // No SKU for the new starter bundle, so using public dictionary name
        case 'bb_starter_bundle':
          bundleName = 'bb_starter_bundle';
          break;
        default:
          bundleName = sku; // If no match, set bundleName to the same sku
          break;
        }
    }

    if (canPurchaseBroadband) {
      if (isBundle && allowRedirectToIce) {
        redirectToSubdomain('ICE', `?bundle=${bundleName}&addressId=${selectedAddressId ?? ''}`, false);
      } else {
        setSelectedCardSku(sku);
        onSelectChange(selectedBBProduct?.kkProduct!, isBundle);
      }
    } else {
      focusOnAddressSelector();
    }
  };

  if (!broadbandProducts?.length) return <BlockLoader />;

  // Workaround Show column in portrait and row in landscape/desktop :(
  const flexClass = isMobile && window.innerHeight > window.innerWidth ? 'flex-col items-center' : 'flex-row';
  // Nasty iPad workaround
  const widthClass = isMobile && !isBrowser && isTablet && window.innerHeight > window.innerWidth ? 'w-3/5' : 'w-full';
  // Ever nastier workaround to change array order for all portrait modes

  const _handleDiscountedPrices = (product: ProductCardFragment): any => {
    const _plan = plans?.find(p => p.product?.sku === product.sku);
    if (!_plan) return Number(product.acquistionOfferPrice);

    return product.type === 'Bundle'
      ? Number(product.acquistionOfferPrice)
      : isActiveSkyStarter
      ? Number(product.upgradeOfferPrice)
      : Number(product.acquistionOfferPrice);
  };

  const handleDescriptionList = (product: ProductCardFragment) => {
    const _plan = plans?.find(p => p.product?.sku === product.sku);
    let price = isActiveSkyStarter ? _plan?.price.fullPrice : _plan?.price.nonStarterPrice;
    let listItem = `Then $${price}/mth`;
    if (!product.detailsList) return product.detailsList;
    let final_List = !isActiveSkyStarter ? product?.detailsList?.slice(1) : product?.detailsList
    const updateArray = [listItem, ...final_List];
    return updateArray;
  };

  return (
    <div className={`container ${widthClass} mx-auto flex justify-center ${flexClass}`}>
      {broadbandProducts
        .sort((a, b) => _handleDiscountedPrices(b.product) - _handleDiscountedPrices(a.product))
        .map(plan => {
          return (
            <div className="mx-3 flex py-sky-md">
              <GenericProductCard
                disabled={false}
                kkProduct={plan.kkProduct}
                kkService={kkService}
                fixedWidth={360}
                inCart={false}
                isStarterInCart={starterAdded}
                configService={configService}
                plan={getExploreProductByPlanSku(plan.product.sku)}
                skyPriceChangePopUp={skySportsPriceChangeData}
                handleProductCardCTA={async () => handleCardSelect(plan.product.sku, plan.product.type === 'Bundle')}
                selectedTuiAddress={selectedTuiAddress}
                card={{
                  bgColor: plan.product.bgColor,
                  occurrenceType: plan.product.occurrenceType,
                  billingFrequency: plan.product.priceFrequencyType,
                  discountText: plan.product.discountText,
                  imageSrc: {
                    text: plan.product.thumbnail?.text ?? '',
                    url: plan.product.thumbnail?.url ?? '',
                  },
                  lozengeText: plan.product.lozengeText,
                  sku: plan.product.sku,
                  subtitle: plan.product.description,
                  discountedPrice: _handleDiscountedPrices(plan.product),
                  //fromPrice:_handlePrice(plan.product),
                  title: plan.product.title,
                  type: plan.product.type,
                  coupon: null,
                  termsAndConditions:
                  isActiveSkyStarter ? rollingTermsContent(isActiveSkyStarter):
                    plan.product.termsAndConditionsCaption,
                  descriptionList:
                    plan.product.type === 'Bundle' ? plan.product.detailsList : handleDescriptionList(plan.product),
                  copyrightText: plan.product.copyrightText,
                  iconList: plan.product.iconList,
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export { CardPlanSelector };
